import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cards-preloader',
  templateUrl: './cards-preloader.component.html',
  styleUrls: ['./cards-preloader.component.scss'],
  animations: [
    trigger('slideRightToLeft', [
      state('in', style({ transform: 'translate(0)' })),
      transition(':enter', [
        style({ transform: 'translate(100%)' }),
        animate('0.5s ease-in-out'),
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class CardsPreloaderComponent {
  @Input() cards!: any[];
  @ViewChild('cardsPreloader') cardsPreloader!: any;
  modalRef!: BsModalRef;
  state = 'inactive';
  visibleCards: any[] = [];
  cardIndex = 0;
  slidingInterval: any;
  showCardsPreloader = true;

  constructor(private modalService: BsModalService) {}

  start() {
    this.openModalWithStaticBackDrop(this.cardsPreloader);
    this.slidingInterval = setInterval(() => {
      this.cardIndex = (this.cardIndex + 1) % this.cards.length;
      this.visibleCards = [this.cards[this.cardIndex]];
    }, 1000);
  }

  openModalWithStaticBackDrop(modal: TemplateRef<any>) {
    const config: ModalOptions = {
      class: `modal-dialog-centered cards-preloader`,
      backdrop: 'static',
      keyboard: false,
    };
    setTimeout(() => {
      if (this.showCardsPreloader) {
        this.modalRef = this.modalService.show(modal, config);
      }
    }, 150);
    this.visibleCards = [this.cards[this.cardIndex]];
  }

  stop() {
    this.showCardsPreloader = false;
    clearInterval(this.slidingInterval);
    this.modalRef?.hide();
  }

  ngOnDestroy() {
    this.stop();
  }
}
