import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  lastRefreshtime: any;
  entityName = '';
  isOhmAdmin = false;

  constructor(private authService: AuthService, public router: Router) {
    this.entityName = authService.getEntity()?.name;
    this.isOhmAdmin = authService.isOhmAdmin();
  }

  ngOnInit(): void {
    this.lastRefreshtime = moment().format('DD MMM, h:mm A');
  }

  hitRefresh() {
    window.location.reload();
  }

  logoutUser() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  switchEntityForAdmin() {
    this.router.navigate(['admin/switch-entity']);
  }
}
