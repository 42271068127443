import { Component, Input, ViewChild } from '@angular/core';
import { ValidationService } from '../core/services/validation.service';

@Component({
  selector: 'ohm-control-messages',
  template: `<mat-error class="mt-2 fs-12" *ngIf="errorMessage !== null">{{
    errorMessage
  }}</mat-error>`,
})
export class ControlMessagesComponent {
  @Input() control!: any;
  @Input() messageKeys!: object;
  @Input() translateParams!: object;
  @Input() parentClass!: string;
  @ViewChild('message', { static: true }) element:
    | {
        nativeElement: {
          closest: (arg0: string) => {
            (): any;
            new (): any;
            classList: {
              (): any;
              new (): any;
              add: { (arg0: string): void; new (): any };
              remove: { (arg0: string): void; new (): any };
            };
          };
        };
      }
    | undefined;
  constructor(private validation: ValidationService) {}

  get errorMessage() {
    this.parentClass = this.parentClass ? this.parentClass : '.form-group';
    for (const propertyName in this.control.errors) {
      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        this.control.errors[propertyName] &&
        (!this.control.pristine || this.control.touched)
      ) {
        this?.element?.nativeElement
          .closest(this.parentClass)
          .classList.add('app-error-field');

        if (
          propertyName === 'apiValidationError' &&
          this.control.errors['hideInLineStatus'] != true
        ) {
          return this.control.errors[propertyName];
        }
        return this.validation.getValidatorErrorMessage(
          propertyName,
          this.messageKeys,
          this.control.errors[propertyName],
          this.translateParams
        );
      }
    }
    this?.element?.nativeElement
      .closest(this.parentClass)
      .classList.remove('app-error-field');
    return null;
  }
}
