<app-empty-page *ngIf="showOfflineInPwa" class="offline-container" image="assets/images/no_network.jpg"
  message="Check your internet connection" primaryButtonTitle="Tap to Retry" (onPrimaryButtonClick)="refresh()">
</app-empty-page>
<div class="hidden-image"><img class="hidden-image" alt="" src="assets/images/no_network.jpg"></div>
<div class="app-c-wrapper" *ngIf="!showOfflineInPwa && canShowLeftPane()">
  <div class="app-c-wrapper__nav">
    <div class="app-c-wrapper__nav--head flex-center">
      <div class="mobile-panel-action-group">
        <button class="panel--close" (click)="menu_panel()">
          <em class="icon-stack"></em>
        </button>
      </div>
      <div class="logo-container">
        <div class="app-c-wrapper__nav--head__logo">
          <a routerLink="">
            <img src="../assets/images/logos/ohm-logo-wide.png" alt="ohm logo">
          </a>
        </div>
        <button class="profile-btn" mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon class="material-icons-outlined white-icon profile-icon">account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <div class="entity-name">
            {{entityName}}
          </div>
          <button *ngIf="canShowSwitchEntity" class="menu-btn" mat-menu-item (click)="switchEntity()">
            <mat-icon class="menu-icon">switch_account</mat-icon>
            <span>Switch Entity</span>
          </button>
          <button class="menu-btn" mat-menu-item (click)="logoutUser()">
            <mat-icon class="menu-icon">logout</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>

    </div>
    <div class="app-c-wrapper-nav-body" [class.active]="menuMobile">
      <div class="app-c-wrapper__nav--list">
        <div class="app-c-wrapper__mobile--nav">
          <div class="mobile-panel-action-group">
            <button class="panel--close" (click)="menu_panel()">
              <em class="icon-close"></em>
            </button>
          </div>
          <div class="app-c-wrapper__nav--head__logo">
            <img src="../assets/images/logos/ohm-logo-wide.png" alt="ohm logo">
          </div>
        </div>

        <ul dense>
          <li *ngFor="let item of items">
            <div style="padding: 0px 24px">
              <a *ngIf='!item.subItems' [routerLink]="item.routerLink ? item.routerLink : null"
                [routerLinkActive]="item.routerLink ? 'active' : ''" (click)="menuMobile = false">
                <div class="app-c-wrapper__nav--list__nav-icon nav-icon">
                  <mat-icon class="material-symbols-outlined">{{item.iconClass}}</mat-icon>
                </div>
                <div class="app-c-wrapper__nav--list__nav-title">
                  {{item.title}}
                </div>
              </a>
            </div>
            <mat-expansion-panel *ngIf='item.subItems' class="background-primary" [class.mat-elevation-z0]="true"
              [expanded]="true" dense>
              <mat-expansion-panel-header>
                <a>
                  <div class="app-c-wrapper__nav--list__nav-icon nav-icon">
                    <mat-icon class="material-symbols-outlined">{{item.iconClass}}</mat-icon>
                  </div>
                  <div class="app-c-wrapper__nav--list__nav-title">
                    {{item.title}}
                  </div>
                </a>
              </mat-expansion-panel-header>
              <mat-nav-list dense>
                <a class="subitem" *ngFor="let subItem of item.subItems"
                  [routerLink]="subItem.routerLink ? subItem.routerLink : null"
                  [routerLinkActive]="subItem.routerLink ? 'active' : ''" (click)="menuMobile = false">
                  <div class="app-c-wrapper__nav--list__nav-title">
                    {{subItem.title}}
                  </div>
                </a>
              </mat-nav-list>
            </mat-expansion-panel>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="app-c-wrapper__body--dashboard-body">
    <router-outlet *ngIf="canShowLeftPane()"></router-outlet>
  </div>
</div>
<ohm-preloader></ohm-preloader>
<router-outlet *ngIf="!showOfflineInPwa && !canShowLeftPane()"></router-outlet>
