import { DateUtilsService } from './date-utils.service';
import { MathUtils } from './math-utils.service';

export class StringsFormatter {
  static getShortAmount(amount: number): string {
    const absAmount = Math.abs(amount);
    if (absAmount >= 10000000) {
      return `${MathUtils.div(amount, 10000000)} CR`;
    } else if (absAmount >= 100000) {
      return `${MathUtils.div(amount, 100000)} L`;
    } else if (absAmount >= 1000) {
      return `${MathUtils.div(amount, 1000)} K`;
    }
    return Number.isNaN(parseFloat(amount + ''))
      ? '-'
      : MathUtils.trimToTwoDecimals(amount) + '';
  }

  static getTenure(tenure: string) {
    if (!tenure) {
      return '-';
    }
    const tenures = tenure.split(',');
    if (tenures.length > 1) {
      return (
        tenures[0].replace(' Months', '') +
        ' - ' +
        tenures[tenures.length - 1].replace(' Months', '')
      );
    } else {
      return tenure;
    }
  }

  static getTimeline(requiredBy: string) {
    return DateUtilsService.getDateInFormat(requiredBy, 'MMM YYYY');
  }

  static getRoi(minRoi: number, maxRoi: number): string {
    if (minRoi || maxRoi) {
      const minRoiFormatted = minRoi ? minRoi : '-';
      const maxRoiFormatted = maxRoi ? maxRoi : '-';
      return `${minRoiFormatted}% - ${maxRoiFormatted}%`;
    }
    return '-';
  }

  static getFormattedInr(value: number | string): string {
    if (value == null) {
      return '';
    }
    return '₹' + this.getCommaSeparatedNumber(value);
  }

  static getCommaSeparatedNumber(value: number | string): string {
    if (value == null) {
      return '';
    }
    return value.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
  }
}

export function isTrue(value: string) {
  return value == 'true';
}
