<div role="group" class="file-input-container" [formGroup]="formGroup" [attr.aria-labelledby]="_formField.getLabelId()">

  <input formControlName="file" hidden (change)="_onFileSelected($event)" [accept]="accept" #fileInput type="file">
  <input #displayName matInput [hidden]="hideDisplayName" type="text" formControlName="displayName" [readonly]="true"
    class="form-control" (click)="fileDto.id || isReadOnly ? null : _onClickUpload($event, fileInput)">
  <div matSuffix style="display:flex">
    <button id="upload" *ngIf="!isReadOnly" (click)="_onClickUpload($event, fileInput)" class="file-prefix-icon"
      mat-icon-button matTooltip="Upload">
      <mat-icon svgIcon="upload"></mat-icon>
    </button>
    <button id="download" *ngIf="fileDto.id && !hideDownloadButton" (click)="_onClickDownload()"
      class="file-prefix-icon" mat-icon-button matTooltip="Download">
      <mat-icon svgIcon="download" class="download-img"></mat-icon>
    </button>
    <mat-icon *ngIf="fileDto.id && fileDto.verifiedString" class="verified-icon" [matTooltip]="fileDto.verifiedString">
      verified
    </mat-icon>
    <button id="delete" *ngIf="fileDto.id && !isReadOnly" (click)="_onClickDelete()" class="file-prefix-icon"
      mat-icon-button matTooltip="Delete">
      <mat-icon class="delete-btn">
        <i class="app-c-icon" ngClass="fa-regular fa-trash-can"></i>
      </mat-icon>
    </button>
  </div>
</div>
