import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/configs/app.config';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoginService } from 'src/app/core/services/login.service';
import { EntityType } from '../../../core/enums/entity.enum';
import { EventService } from '../../../core/services/event.service';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginDetailsForm!: FormGroup;
  actionText = '';
  submitButtonText = '';
  showOtp = false;

  @ViewChild('otp') otpField!: ElementRef;
  @ViewChild('submitBtn') submitBtn!: MatButton;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private authService: AuthService,
    private userService: UserService,
    public eventService: EventService,
    public router: Router,
    private route: ActivatedRoute,
    public appConstants: Constants
  ) {}

  ngOnInit(): void {
    this.submitButtonText = 'Send OTP';
    this.buildLoginForm();
  }

  buildLoginForm() {
    this.loginDetailsForm = this.fb.group(
      {
        mobileNumber: [
          '',
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern(this.appConstants.mobileNoPattern),
          ],
        ],
        otp: [''],
      },
      { updateOn: 'blur' }
    );
  }

  private validateFields() {
    this.submitBtn._elementRef.nativeElement.focus();
    for (const control of Object.values(this.loginDetailsForm.controls)) {
      control.markAsTouched();
    }
    const values = this.loginDetailsForm.getRawValue();
    values.mobileNumber = this.loginDetailsForm.value.mobileNumber + '';
    return values;
  }

  login(): void {
    if (this.showOtp) {
      this.validateOtp();
    } else {
      this.initiateOtp();
    }
  }

  initiateOtp() {
    const values = this.validateFields();
    if (values && this.loginDetailsForm.valid) {
      this.loginService.sendOtp(values).subscribe((response: any) => {
        this.showOtp = true;
        this.submitButtonText = 'Validate OTP';
        setTimeout(() => {
          this.otpField.nativeElement.focus();
        }, 300);
      });
    }
  }

  validateOtp() {
    const values = this.validateFields();
    if (values && this.loginDetailsForm.valid) {
      if (!values.otp) {
        this.loginDetailsForm.controls['otp'].setErrors({ required: true });
        return;
      }
      this.loginService.validateOtp(values).subscribe({
        next: (response: any) => {
          if (this.authService.login(response)) {
            this.onLoginSuccessful();
          }
        },
        error: (error) => {
          this.submitButtonText = 'Send OTP';
          this.otpField.nativeElement.value = '';
          this.showOtp = false;
        },
      });
    }
  }

  onLoginSuccessful() {
    if (!this.authService.isOhmAdmin()) {
      this.redirectToNextPage();
      return;
    }
    this.userService
      .getUserRolePermissions(this.authService.getUserData().id)
      .subscribe({
        next: (response: any) => {
          this.authService.setUserRolePermissions(response);
          this.redirectToNextPage();
        },
        error: (error: any) => {
          this.logoutUser();
        },
      });
  }

  redirectToNextPage() {
    let path;
    if (this.authService.isOhmAdmin()) {
      path = 'admin/switch-entity';
    } else if (this.authService.isMasterDealer()) {
      path = 'master-dealer/switch-entity';
    } else {
      const entity = this.authService.getEntity();
      if (entity?.id && entity?.isActive) {
        const redirectUrl = this.route.snapshot.queryParams['redirectUrl'];
        path = redirectUrl ? redirectUrl : 'home';
      } else if (!this.authService.getUserData().entityType) {
        path = 'user/update-user-type';
      } else if (
        this.authService.getUserData().entityType === EntityType.Dealer
      ) {
        path = 'user/dealer-signup';
      } else if (this.authService.getUserData().emailVerified) {
        path = 'user/signup';
      } else {
        path = 'user/verify-email';
      }
    }
    this.loginDetailsForm.reset();
    this.eventService.sendEvent({
      eventName: 'logged_in',
    });
    const pathComponents = path.split('?');
    const queryParams: any = {};
    if (pathComponents.length > 1 && pathComponents[1]) {
      const queryStrings = new URLSearchParams(pathComponents[1]);
      queryStrings.forEach((value, key) => {
        queryParams[key] = value;
      });
    }
    this.router.navigate([pathComponents[0]], {
      queryParams,
    });
  }

  resendOtp() {
    this.initiateOtp();
    this.otpField.nativeElement.value = '';
    this.otpField.nativeElement.focus();
  }

  logoutUser() {
    this.authService.logout();
    this.router.navigate(['/']);
  }
}
