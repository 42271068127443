import { Injectable } from '@angular/core';
import { DocSubType } from '../../core/enums/doc-sub-types.enum';
import { DocType } from '../../core/enums/doc-types.enum';
import { EntityType } from '../../core/enums/entity.enum';
import { AuthService } from '../../core/services/auth.service';
import { DocumentsService } from '../../core/services/documents.service';
import { NotifyService } from '../../core/services/notify.service';
import { FileDto, FileInput } from '../file-input/file-input.component';

@Injectable()
export class FileUtilsService {
  constructor(
    private documentsService: DocumentsService,
    private notifyService: NotifyService,
    private authService: AuthService
  ) {}

  uploadFile(
    file: any,
    entityType: EntityType,
    docType: DocType,
    docTypeId: string | null,
    docSubType: DocSubType,
    onUploaded: (fileDto: FileDto) => void,
    docSubTypeId: string | null = null,
    entityId: string = this.authService.getEntity().id
  ) {
    const data: any = {
      file,
      entityId,
      entityType,
      docType,
      docSubType: docSubType,
    };
    if (docTypeId) {
      data.docTypeId = docTypeId;
    }
    if (docSubTypeId) {
      data.docSubTypeId = docSubTypeId;
    }
    this.documentsService.upload(data).subscribe((response: any) => {
      if (response.id && onUploaded) {
        onUploaded(new FileDto(response.id, response.displayName));
      }
    });
  }

  downloadFile(fileDto: FileDto) {
    this.downloadFileUsingDocId(fileDto.id);
  }

  downloadFileUsingDocId(docId: string) {
    this.documentsService.getSignedUrl(docId).subscribe((response: any) => {
      const url = response.url;
      const link = document.createElement('a');
      link.href = url;
      link.click();
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(url);
      }, 100);
    });
  }

  delete(fileInput: FileInput, docTypeId?: string) {
    const fileDto = fileInput.value!;
    this.documentsService
      .delete(fileDto.id, docTypeId)
      .subscribe((response: any) => {
        if (response.detailsUpdated) {
          fileInput.writeValue(null);
          this.notifyService.showSuccess('Document removed successfully');
        } else {
          this.notifyService.showError("Couldn't remove document");
        }
      });
  }
}
