import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { distinctUntilChanged } from 'rxjs/operators';
import { Constants } from './../../configs/app.config';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(
    private appConstants: Constants,
    private translate: TranslateService
  ) {}

  static emailValidator(control: any) {
    // RFC 2822 compliant regex
    // tslint:disable-next-line:max-line-length
    if (
      control.value &&
      control.value.match(
        /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/
      )
    ) {
      return null;
    } else {
      return { invalidEmail: true };
    }
  }
  static emailValidatorOptional(control: any) {
    // RFC 2822 compliant regex
    // tslint:disable-next-line:max-line-length
    if (control.value) {
      return ValidationService.emailValidator(control);
      //  return this.emailValidator(control)
    } else {
      return null;
    }
  }

  static matchPasswordValidator(group: any, form: any) {
    const password = group.controls ? group.controls.newPassword : null;
    const confirmPassword = group.controls
      ? group.controls.confirmPassword
      : null;
    if (!password || !confirmPassword) {
      return null;
    }
    if (password.pristine || confirmPassword.pristine) {
      return null;
    }
    if (password.value === confirmPassword.value) {
      return (confirmPassword.errors = null);
    }
    return (confirmPassword.errors = {
      passwordMismatch: true,
    });
  }

  // Method to get validation error message
  public getValidatorErrorMessage(
    validatorName: string,
    messageKeys?: any,
    validatorValue?: any,
    translateParams?: any
  ) {
    if (!_.isUndefined(messageKeys)) {
      if (messageKeys[validatorName]) {
        return this.translate.instant(
          messageKeys[validatorName],
          translateParams
        );
      }
      return null;
    } else {
      if (
        !this.appConstants.ValidationMessageKeys[validatorName] ||
        !validatorValue.requiredLength
      ) {
        return null;
      }
      return this.translate.instant(
        this.appConstants.ValidationMessageKeys[validatorName],
        { value: validatorValue.requiredLength }
      );
    }
  }

  // Conditional validation
  static conditional(conditional: any, validator: any) {
    return (control: { _parent: any }) => {
      this.revalidateOnChanges(control);

      if (control && control._parent) {
        if (conditional(control._parent)) {
          return validator(control);
        }
      }
    };
  }

  // Reevalidate on changes
  static revalidateOnChanges(control: any): void {
    if (control && control._parent && !control._revalidateOnChanges) {
      control._revalidateOnChanges = true;
      control._parent.valueChanges
        .pipe(
          distinctUntilChanged((a: any, b: any) => {
            // These will always be plain objects coming from the form, do a simple comparison
            if ((a && !b) || (!a && b)) {
              return false;
            } else if (
              a &&
              b &&
              Object.keys(a).length !== Object.keys(b).length
            ) {
              return false;
            } else if (a && b) {
              for (const i in a) {
                if (a[i] !== b[i]) {
                  return false;
                }
              }
            }
            return true;
          })
        )
        .subscribe(() => {
          control.updateValueAndValidity();
        });

      control.updateValueAndValidity();
    }
    return;
  }

  processErrorResponseFromAPI(
    form: FormGroup,
    errorResponse: any,
    hideInLineStatus: any
  ) {
    const err = {};
    for (const key in errorResponse) {
      if (errorResponse.hasOwnProperty(key)) {
        const errorMessage = errorResponse[key];
        const formElementNames = key.split('.');
        const controlName: any = formElementNames.splice(0, 1)[0];
        // const controlName = key;
        if (formElementNames.length > 0) {
          let control: any = form.get(controlName);
          formElementNames.forEach((item) => {
            control = control?.get(this.camelCase(item));
          });
          control.setErrors({
            apiValidationError: errorMessage,
            hideInLineStatus,
          });
        } else {
          const control = form.get(controlName);
          /*err[controlName] = { apiValidationError: errorMessage, hideInLineStatus };
                    control.markAllAsTouched();
                    control.markAsPristine();
                    control.setErrors({ apiValidationError: errorMessage, hideInLineStatus });*/
        }
      }
    }
  }

  private camelCase(str: any) {
    return str
      .replace(/\s(.)/g, (a: any) => {
        return a.toUpperCase();
      })
      .replace(/\s/g, '')
      .replace(/^(.)/, (b: any) => {
        return b.toLowerCase();
      });
  }
}
