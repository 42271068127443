<div class="app-c-wrapper__body--header">
  <!-- <div class="tagline-status"> -->
  <!-- <img src="../../assets/images/logos/ohm-logo.svg" width="100" height="100"> -->
  <!-- <div class="tagline-status-last-update flex-center">
            <div class="refreshed-time">
                Last refreshed on {{lastRefreshtime}}
            </div>
            <button class="refresh-button" (click)="hitRefresh()">
                <em class="icon-refresh"></em>
            </button>
        </div> -->
  <!-- </div> -->
  <div class="profile-options">
    <div class="btn-group" dropdown>
      <button id="button-basic" dropdownToggle type="button" class="btn btn-profile dash-box dropdown-toggle"
        aria-controls="dropdown-basic">
        <div class="profile-dropdown">
          <div class="profile-pic">
            <img src="../../../../assets/icons/user.svg" alt="profile" width="40" height="40">
          </div>
          {{entityName}}
        </div>
      </button>
      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right dash-box dropdown-profile-list"
        role="menu" aria-labelledby="menu dropdown">
        <li *ngIf="isOhmAdmin" role="menuitem" class="dropdown-profile-list-item" (click)="switchEntityForAdmin()">
          <a class="dropdown-item">
            <div><em class="icon-logout"></em> </div>
            <div class="dropdown-title u-truncate">Switch Entity</div>
          </a>
        </li>
        <li role="menuitem" class="dropdown-profile-list-item" (click)="logoutUser()">
          <a class="dropdown-item">
            <div><em class="icon-logout"></em> </div>
            <div class="dropdown-title u-truncate">Logout</div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
