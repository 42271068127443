import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatOptionModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgChartsModule } from 'ng2-charts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { ButtonComponent } from './button/button.component';
import { CardsPreloaderComponent } from './cards-preloader/cards-preloader.component';
import { ControlMessagesComponent } from './control-messages.component';
import { EmptyPageComponent } from './empty-page/empty-page.component';
import { FileInput } from './file-input/file-input.component';
import { HeaderComponent } from './header/header.component';
import { NumberInput } from './number-input/number-input.component';
import { SelectAllOption } from './select-all-option/select-all-option.component';
import { SelectInput } from './select-input/select-input.component';
import { ToggleComponentComponent } from './toggle-component/toggle-component.component';
import { AlertUtilsService } from './utils/alert-utils.service';
import { DatePickerFormatDirective } from './utils/date-picker-format.directive';
import { FileUtilsService } from './utils/file-utils.service';
import { MatInputCommifiedDirective } from './utils/mat-input-commified.directive';
import { PdfInfoIconComponent } from './utils/pdf-info-icon.component';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD-MM-YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    /* angular stuff */
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    NgChartsModule,
    MatOptionModule,
  ],
  declarations: [
    ControlMessagesComponent,
    HeaderComponent,
    FileInput,
    ActionButtonsComponent,
    SelectInput,
    NumberInput,
    DatePickerFormatDirective,
    EmptyPageComponent,
    PdfInfoIconComponent,
    MatInputCommifiedDirective,
    ButtonComponent,
    ToggleComponentComponent,
    CardsPreloaderComponent,
    SelectAllOption,
  ],
  exports: [
    /* angular stuff */
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    /* 3rd party components */
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    NgChartsModule,
    /* our own custom components */
    ControlMessagesComponent,
    HeaderComponent,
    FileInput,
    ActionButtonsComponent,
    SelectInput,
    NumberInput,
    DatePickerFormatDirective,
    EmptyPageComponent,
    PdfInfoIconComponent,
    MatInputCommifiedDirective,
    ButtonComponent,
    ToggleComponentComponent,
    CardsPreloaderComponent,
    SelectAllOption,
  ],
  providers: [
    BsModalService,
    BsModalRef,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    { provide: FileUtilsService, useClass: FileUtilsService },
    { provide: AlertUtilsService, useClass: AlertUtilsService },
  ],
})
export class SharedModule {}
