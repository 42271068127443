import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NgControl,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { MAX_FILE_SIZE } from '../../core/constants/config';
import { NotifyService } from '../../core/services/notify.service';
import { FileUtilsService } from '../utils/file-utils.service';

export class FileDto {
  constructor(
    public id: string,
    public displayName: string,
    public verifiedString?: string
  ) {}
}

/** Custom `MatFormFieldControl` for file upload & download. */
@Component({
  selector: 'file-input',
  templateUrl: 'file-input.component.html',
  styleUrls: ['file-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FileInput }],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class FileInput
  implements ControlValueAccessor, MatFormFieldControl<FileDto>, OnDestroy
{
  static nextId = 0;
  @ViewChild('displayName') displayName!: HTMLInputElement;

  formGroup = this._formBuilder.group({
    file: [undefined],
    displayName: [''],
  });
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'file-input';
  id = `file-input-${FileInput.nextId++}`;
  fileDto = new FileDto('', '');
  @Input() isReadOnly: boolean = false;

  @Input() control!: AbstractControl | null;
  @Input() hideDownloadButton: boolean = false;

  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    const {
      value: { displayName },
    } = this.formGroup;

    return !displayName;
  }

  get shouldLabelFloat() {
    return !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy!: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder!: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formGroup.disable() : this.formGroup.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): FileDto | null {
    if (this.formGroup.valid) {
      return this.fileDto;
    }
    return null;
  }
  set value(fileDto: FileDto | null) {
    this.fileDto = fileDto ?? new FileDto('', '');
    this.formGroup.patchValue(
      { displayName: this.fileDto.displayName, file: undefined },
      { onlySelf: true }
    );
    this.stateChanges.next();
  }

  @Input()
  get accept(): string {
    return this._accept;
  }
  set accept(value: string) {
    this._accept = value;
    this.stateChanges.next();
  }
  private _accept!: string;

  @Input()
  get docSubType(): string {
    return this._docSubType;
  }
  set docSubType(value: string) {
    this._docSubType = value;
    this.stateChanges.next();
  }
  private _docSubType!: string;

  @Input()
  get hideDisplayName(): boolean {
    return this._hideDisplayName;
  }
  set hideDisplayName(value: boolean) {
    this._hideDisplayName = value;
    this.stateChanges.next();
  }
  private _hideDisplayName!: boolean;

  get errorState(): boolean {
    return this.formGroup.invalid;
  }

  @Output() onClickDownload: EventEmitter<any> = new EventEmitter();
  @Output() onFileSelected: EventEmitter<any> = new EventEmitter();
  @Output() onClickDelete: EventEmitter<any> = new EventEmitter();

  constructor(
    private _formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private notifyService: NotifyService,
    private fileUtilsService: FileUtilsService,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.writeValue = this.writeValue.bind(this);
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.file-input-container'
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {}

  writeValue(fileDto: FileDto | null): void {
    this.value = fileDto;
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _onClickUpload(event: any, fileInput: any) {
    event.preventDefault();
    fileInput.click();
  }

  _onClickDownload() {
    this.onClickDownload.emit(this.value);
  }

  _onClickDelete() {
    if (this.onClickDelete.observed) {
      this.onClickDelete.emit(this);
    } else {
      this.fileUtilsService.delete(this);
    }
  }

  _onFileSelected(event: any): void {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileSizeLimit = MAX_FILE_SIZE;
      if (selectedFile.size > fileSizeLimit) {
        if (this.control) {
          this.control.setErrors({ maxFileSize: true });
          this.control.markAllAsTouched();
        } else {
          this.notifyService.showError('Please upload a file less than 5mb');
        }
        return;
      }
      this.onFileSelected.emit([
        selectedFile,
        this.docSubType,
        this.writeValue,
      ]);
    }
    this.formGroup.patchValue({ file: undefined }, { onlySelf: true });
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.onChange(this.value);
  }
}
