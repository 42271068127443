import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'select-all-option',
  templateUrl: 'select-all-option.component.html',
  styleUrls: ['select-all-option.component.scss'],
})
export class SelectAllOption {
  @Input() selectedValues: any[] = [];
  @Input() values: any[] = [];
  @Input() label = 'Select All';
  @Output() setSelectedValues = new EventEmitter<any[]>();
  @ViewChild('option') option!: MatOption;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.setOptionSelectionState();
    this.changeDetector.detectChanges();
  }

  setOptionSelectionState() {
    this.isAllValuesSelected() ? this.option.select() : this.option.deselect();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedValues'] && this.option) {
      this.selectedValues = changes['selectedValues'].currentValue;
      this.setOptionSelectionState();
    }
  }

  isAllValuesSelected(): boolean {
    return (
      this.values.length > 0 &&
      this.selectedValues?.length > 0 &&
      this.values.length <= this.selectedValues.length
    );
  }

  onSelectionChange() {
    this.setSelectedValues.emit(this.option.selected ? this.values : []);
  }
}
