import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as mixpanel from 'mixpanel-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private gaService: GoogleAnalyticsService) {
    mixpanel.init(environment.mixpanelToken, {
      debug: !environment.production,
      track_pageview: false,
      persistence: 'localStorage',
    });
  }

  track(
    action: string,
    category?: string,
    label?: string,
    value?: number,
    properties?: any
  ): void {
    this.gaService.event(action, category, label, value);
    mixpanel.track(action, {
      ..._.omitBy(
        {
          category,
          label,
          value,
        },
        _.isNil
      ),
      ...properties,
    });
  }

  trackEvent(name: string, properties?: any): void {
    this.gaService.event(name);
    mixpanel.track(name, {
      ...properties,
    });
  }

  trackPageView(path: string) {
    mixpanel.default.track_pageview();
  }

  setUser(userId: string, userDetails: any, entityDetails: any) {
    const allDetails = {
      ...userDetails,
      ...entityDetails,
    };
    const userProperties = userDetails.isOhmAdmin ? userDetails : allDetails;
    this.gaService.set('userId', userId);
    this.gaService.set('user_properties', userProperties);
    mixpanel.register(allDetails);
    mixpanel.identify(userId);
    mixpanel.people.set(userProperties);
  }

  resetUser() {
    mixpanel.reset();
  }
}
