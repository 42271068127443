import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-button-component',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements ICellRendererAngularComp, OnDestroy {
  private params: any;
  buttonTitle: string;
  isHidden = false;

  constructor() {
    this.buttonTitle = 'View';
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  ngOnDestroy(): void {
    //throw new Error('Method not implemented.');
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (this.params.buttonTitle) {
      this.buttonTitle = this.params.buttonTitle;
    }
    if (this.params.getButtonTitle) {
      this.buttonTitle = this.params.getButtonTitle(this.params.data);
    }
    if (this.params.isHidden) {
      this.isHidden = this.params.isHidden(this.params.data);
    }
  }

  btnClickedHandler($event: any) {
    this.params.clicked($event, this.params.data);
  }
}
