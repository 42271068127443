import { Platform } from '@angular/cdk/platform';
import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Constants } from './configs/app.config';
import { BorrowerProfileStatus } from './core/enums/borrower-profile-status.enum';
import { CustomerPreference } from './core/enums/customer-preference.enum';
import { Features } from './core/enums/features.enum';
import { LenderProfileStatus } from './core/enums/lender-profile-status.enum';
import { AnalyticsService } from './core/services/analytics.service';
import { AuthService } from './core/services/auth.service';
import { EventService } from './core/services/event.service';
import { FeatureService } from './core/services/feature.service';
import { UserService } from './core/services/user.service';
import { UtilsService } from './shared/utils/utils.service';

const BORROWER_COMPANY_PROFILE_ITEMS = [
  {
    routerLink: 'borrower/profile/info',
    title: 'Company Information',
  },
  {
    routerLink: 'borrower/profile/directors',
    title: 'Director & Promoters',
  },
  {
    routerLink: 'borrower/profile/fleets',
    title: 'Fleet Details',
  },
  {
    routerLink: 'borrower/profile/financials',
    title: 'Key Financials',
  },
  {
    routerLink: 'borrower/profile/share-holdings',
    title: 'Share Holding Pattern',
  },
  {
    routerLink: 'borrower/profile/existing-borrowings',
    title: 'Existing Borrowings',
  },
  {
    routerLink: 'borrower/profile/b2b-contracts',
    title: 'B2B Contracts',
  },
  {
    routerLink: 'borrower/profile/financial-uploads',
    title: 'Financial Docs Upload',
  },
];

const BORROWER_PANE_ITEMS = [
  {
    iconClass: 'grid_view',
    title: 'Funding Asks',
    routerLink: 'borrower/deals',
  },
  {
    iconClass: 'manage_accounts',
    title: 'Company Profile',
    subItems: BORROWER_COMPANY_PROFILE_ITEMS,
  },
];

const DEALER_STORE_MANAGEMENT_SUB_ITEMS = [
  {
    routerLink: 'dealer/store-management/models',
    title: 'Vehicle Models',
  },
];

const APPROVED_DEALER_STORE_MANAGEMENT_SUB_ITEMS = [
  {
    routerLink: 'dealer/store-management/lenders',
    title: 'Lenders',
  },
  {
    routerLink: 'dealer/store-management/reports',
    title: 'Reports',
  },
];

const APPROVED_DEALER_PANE_ITEMS = [
  {
    iconClass: 'person_add',
    title: 'New Enquiry',
    routerLink: 'dealer/available-models',
  },
  {
    iconClass: 'contract',
    title: 'Customer Loans',
    routerLink: 'dealer/loan-requests/list',
  },
];

const DEALER_STORE_MANAGEMENT_PANE_ITEM: any = {
  iconClass: 'store',
  title: 'Store Management',
};

const OHM_PULSE = {
  iconClass: 'insights',
  title: 'Ohm Pulse',
  routerLink: 'ohm-pulse',
};

const LIVE_DEALS = {
  iconClass: 'grid_view',
  title: 'Live Deals',
  routerLink: 'lender/deals/live',
};

const B2C_LEADS = {
  iconClass: 'format_list_bulleted',
  title: 'B2C Leads',
  routerLink: 'lender/b2c',
};

const LENDER_PANE_ITEMS = [
  {
    iconClass: 'manage_accounts',
    title: 'Company Profile',
    routerLink: 'lender/profile/info',
  },
];

const DEAL_DETAILS_PANE_ITEMS = [
  {
    routerLink: 'lender/deals/summary',
    title: 'Deal Summary',
  },
  {
    routerLink: 'borrower/deals/',
    title: 'Funding Ask',
  },
  {
    routerLink: 'borrower/profile/info',
    title: 'Company Information',
  },
  {
    routerLink: 'borrower/profile/directors',
    title: 'Director & Promoters',
  },
  {
    routerLink: 'borrower/profile/fleets',
    title: 'Fleet Details',
  },
  {
    routerLink: 'borrower/profile/financials',
    title: 'Key Financials',
  },
  {
    routerLink: 'borrower/profile/share-holdings',
    title: 'Share Holding Pattern',
  },
  {
    routerLink: 'borrower/profile/existing-borrowings',
    title: 'Existing Borrowings',
  },
  {
    routerLink: 'borrower/profile/b2b-contracts',
    title: 'B2B Contracts',
  },
  {
    routerLink: 'borrower/profile/financial-uploads',
    title: 'Financial Docs Upload',
  },
  {
    routerLink: 'lender/deals/responses',
    title: 'Deal Responses',
  },
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ohm-lending-web';
  menuMobile: boolean = false;
  hideLeftPane = false;
  subscription: Subscription;
  items: any = [];
  featureList: any = [];
  entityName = '';
  canShowSwitchEntity = false;
  showOfflineInPwa: boolean = false;
  modalPwaEvent: any;
  modalPwaPlatform: string | undefined;
  _onDestroy = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    public authService: AuthService,
    public eventService: EventService,
    private appConstants: Constants,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private featureService: FeatureService,
    private analyticsService: AnalyticsService,
    private platform: Platform,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) {
    this.translateService.use(this.appConstants.defaultLanguage);
    const icons = [
      {
        name: 'home',
        path: '../assets/icons/home.svg',
      },
      {
        name: 'user',
        path: '../assets/icons/user.svg',
      },
      {
        name: 'company-profile',
        path: '../assets/icons/company-profile.svg',
      },
      {
        name: 'upload',
        path: '../assets/icons/upload.svg',
      },
      {
        name: 'download',
        path: '../assets/icons/download.svg',
      },
      {
        name: 'deals',
        path: '../assets/icons/deals.svg',
      },
    ];
    this.addIcons(icons);
    if (this.authService.isLoggedIn() && this.authService.isOhmAdmin()) {
      this.getUserRolePermissions();
    }
    this.setLeftPaneItems();
    this.router.events
      .pipe(takeUntil(this._onDestroy))
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.analyticsService.trackPageView(event.urlAfterRedirects);
        if (
          (event.url === '/user/verify-email' ||
            event.url === '/user/update-user-type' ||
            event.url === '/user/signup' ||
            event.url === '/user/dealer-signup' ||
            event.url.startsWith('/admin/') ||
            event.url.startsWith('/master-dealer/')) &&
          this.authService.isLoggedIn()
        ) {
          this.hideLeftPane = true;
          this.featureList = [];
        } else {
          this.hideLeftPane = false;
          this.entityName = authService.getEntity()?.name;
          this.canShowSwitchEntity =
            authService.isOhmAdmin() || authService.isMasterDealer();

          this.setLeftPaneItems(event.url);
        }
      });
    this.subscription = this.eventService.getEvent().subscribe((message) => {
      if (message.eventName && message.eventName === 'logged_in') {
        this.setLeftPaneItems();
      }
    });
  }

  addIcons(icons: any[]) {
    for (const icon of icons) {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    }
  }

  ngOnInit() {
    this.updateOnlineStatus();
    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));

    this.authService.isLoggedIn();
  }

  getUserRolePermissions() {
    const userId = this.authService.getUserData().id;
    this.userService.getUserRolePermissions(userId).subscribe({
      next: (response: any) => {
        this.authService.setUserRolePermissions(response);
      },
      error: (error: any) => {
        if (!this.authService.getUserPermissions()) {
          this.logoutUser();
        }
      },
    });
  }

  getAllFeatures() {
    this.featureService.getAllFeatures().subscribe((features: any) => {
      this.featureList = features;
      this.checkEnabledFeatures();
    });
  }

  checkEnabledFeatures() {
    for (const feature of this.featureList) {
      if (feature.enabled && feature.name === Features.OhmPulse) {
        this.items.push(OHM_PULSE);
      }
    }
  }

  setLeftPaneItems(urlPath?: string) {
    if (this.authService.isLoggedIn()) {
      if (this.authService.isLender()) {
        if (urlPath) {
          const paths = urlPath.split('/');
          if (paths.length > 4) {
            if (paths[1] === 'borrower') {
              if (paths[3] === 'deals') {
                this.items = this.getPaneItems(paths[2], paths[4]);
                return;
              }
              this.items = this.getPaneItems(paths[2], paths[3]);
              return;
            }
            if (paths[5] === 'summary' || paths[5] === 'responses') {
              this.items = this.getPaneItems(paths[3], paths[4]);
              return;
            }
          }
        }
        const lenderItems = [...LENDER_PANE_ITEMS];
        const customerPreferences = this.authService
          .getEntity()
          .customerPreferences.split(',');

        if (customerPreferences.includes(CustomerPreference.B2b2c)) {
          lenderItems.unshift(B2C_LEADS);
        }
        if (
          this.authService.getEntity().status == LenderProfileStatus.Approved &&
          customerPreferences.includes(CustomerPreference.B2bBusiness)
        ) {
          lenderItems.unshift(LIVE_DEALS);
        }
        this.items = lenderItems;
      } else if (this.authService.isDealer()) {
        this.items = [];
        let manageStoreSubItems = [...DEALER_STORE_MANAGEMENT_SUB_ITEMS];
        if (
          this.authService.getEntityStatus() == BorrowerProfileStatus.Approved
        ) {
          manageStoreSubItems.push(
            ...APPROVED_DEALER_STORE_MANAGEMENT_SUB_ITEMS
          );
          this.items.unshift(...APPROVED_DEALER_PANE_ITEMS);
        }
        DEALER_STORE_MANAGEMENT_PANE_ITEM['subItems'] = manageStoreSubItems;
        this.items.push(DEALER_STORE_MANAGEMENT_PANE_ITEM);
      } else if (this.authService.isBorrower()) {
        this.items = [...BORROWER_PANE_ITEMS];
      }
      if (urlPath) {
        if (
          urlPath.startsWith('/admin/') ||
          urlPath.startsWith('/master-dealer/')
        ) {
          this.featureList = [];
          return;
        }
        if (!this.authService.isDealer()) {
          this.featureList.length
            ? this.checkEnabledFeatures()
            : this.getAllFeatures();
        }
      }
    }
  }

  getPaneItems(borrowerId: string, fundRequestId: string) {
    const items = [];
    for (const item of DEAL_DETAILS_PANE_ITEMS) {
      if (item.routerLink.includes('borrower/profile')) {
        items.push({
          ...item,
          routerLink: item.routerLink.replace(
            'borrower/profile',
            `borrower/${borrowerId}/${fundRequestId}/profile`
          ),
        });
      } else if (item.routerLink.includes('lender/deals/')) {
        items.push({
          ...item,
          routerLink: item.routerLink.replace(
            'lender/deals/',
            `lender/deals/${borrowerId}/${fundRequestId}/`
          ),
        });
      } else if (item.routerLink.includes('borrower/deals')) {
        items.push({
          ...item,
          routerLink: item.routerLink.replace(
            'borrower/deals',
            `borrower/${borrowerId}/deals/${fundRequestId}`
          ),
        });
      }
    }
    return items;
  }

  private updateOnlineStatus(): void {
    this.showOfflineInPwa = !window.navigator.onLine && UtilsService.isPwa();
  }

  private loadModalPwa(): void {
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.modalPwaEvent = event;
        this.snackBar
          .open('Add this WEB app to home screen', 'Install', {
            duration: 3 * 1000,
          })
          .onAction()
          .subscribe(() => {
            this.modalPwaEvent.prompt();
          });
      });
    } else if (this.platform.IOS && this.platform.SAFARI) {
      const isInStandaloneMode =
        'standalone' in window.navigator &&
        (<any>window.navigator)['standalone'];
      if (!isInStandaloneMode) {
        this.snackBar.open(
          'To install this WEB app on your device, tap the "Menu" button and then "Add to home screen" button',
          'OK',
          { duration: 5 * 1000 }
        );
      }
    }
  }

  refresh(): void {
    window.location.reload();
  }

  menu_panel() {
    this.menuMobile = !this.menuMobile;
  }

  canShowLeftPane() {
    return this.authService.isLoggedIn() && !this.hideLeftPane;
  }

  logoutUser() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  switchEntity() {
    if (this.authService.isOhmAdmin()) {
      this.router.navigate(['admin/switch-entity']);
    } else if (this.authService.isMasterDealer()) {
      this.router.navigate(['master-dealer/switch-entity']);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
