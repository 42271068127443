import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorage {
  /**
   * Creates an instance of LocalStorage.
   */
  constructor(private cryptoService: CryptoService) {
    this.cryptoService = cryptoService;
  }

  /**
   * Store data to local storage after encryption
   * @memberOf LocalStorage
   */
  store(key: any, data: any) {
    const keyHashed = this.getOriginalHashedKey(key);
    let encryptedData;
    if (environment.production) {
      encryptedData = this.cryptoService
        .encrypt(JSON.stringify(data))
        .toString();
    } else {
      encryptedData = JSON.stringify(data);
    }
    window.localStorage.setItem(keyHashed, encryptedData);
  }

  /**
   * Remove key from localstorage
   *
   */
  remove(key: any) {
    const keyHashed = this.getOriginalHashedKey(key);
    window.localStorage.removeItem(keyHashed);
  }

  /**
   * Get data from local storage after decryption
   *
   * @memberOf LocalStorage
   */
  get(key: any) {
    const keyHashed = this.getOriginalHashedKey(key);
    const encryptedData = window.localStorage.getItem(keyHashed);
    if (!_.isNull(encryptedData)) {
      let data;
      if (environment.production) {
        try {
          const decryptedData = this.cryptoService.decrypt(encryptedData);
          if (!decryptedData) {
            this.clearAll();
            return null;
          }
          data = JSON.parse(decryptedData);
        } catch (error) {
          this.clearAll();
          return null;
        }
      } else {
        data = JSON.parse(encryptedData);
      }
      if (!_.isEmpty(data)) {
        return data;
      }
    }
    return null;
  }

  /**
   * Method to clear all local storage items (destructive!)
   *
   *
   * @memberOf LocalStorage
   */
  clearAll() {
    window.localStorage.clear();
  }

  /**
   * Method to return correct hashed key
   *
   */
  getOriginalHashedKey(key: any) {
    if (environment.production) {
      const originalKey = key;
      const keyHashed = this.cryptoService.md5(originalKey);
      return keyHashed;
    } else {
      return key;
    }
  }
}
