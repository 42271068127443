import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-toggle-component',
  templateUrl: './toggle-component.component.html',
  styleUrls: ['./toggle-component.component.scss'],
})
export class ToggleComponentComponent {
  private params: any;
  isOn!: boolean;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isOn = this.params.data.isActive;
  }

  btnClickedHandler() {
    this.params.clicked(this.params.data);
  }
}
