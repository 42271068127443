import { Component } from '@angular/core';
import tippy from 'tippy.js';

@Component({
  selector: 'ohm-pdf-info-icon',
  template: `<mat-icon id="infoIcon" class="material-icons-outlined info-icon"
    >info</mat-icon
  >`,
})
export class PdfInfoIconComponent {
  ngAfterViewInit(): void {
    tippy('#infoIcon', {
      content: `<a
        href="https://www.gtricks.com/chrome/quickly-remove-password-from-pdf-using-google-chrome/"
        target="_blank"
        rel="noopener noreferrer"
      >
      how to remove password?
    </a>`,
      allowHTML: true,
      interactive: true,
      theme: 'light-border',
    });
  }
}
