export const environment = {
  production: true,
  APIBasePath: 'https://buzzapidev.ohmmobility.com/api/',
  googleTrackingId: 'G-S6R5BQNPTC',
  mixpanelToken: '090a777dcd2a981834f9f945f4146024',
  metabaseBaseUrl: 'https://dashboard.ohmmobility.com',
  borrowerDashboardId: 6,
  lenderDashboardId: 7,
  SECRET_KEY_FOR_ENCRYPTION_WITH_IV: 'a47410c7943d4be9919767f3488c55d1',
  ENCRYPTION_IV: '8be0f1584751452c',
  FINBOX_API_KEY: 'ac3a7d66e8c247129387f6e59567b1f2',
};
