<div class="mat-select-input" [attr.aria-labelledby]="_formField.getLabelId()">
  <input [matTooltip]="valueOnDisabledState" *ngIf="disabled" matInput type="text" [value]="valueOnDisabledState"
    [readonly]="true" class="form-control">
  <mat-select *ngIf="!disabled" [formControl]="formControl" [(value)]="selectedOptions" multiple
    (focusin)="onFocusIn($event)" (focusout)="onFocusOut($event)">
    <mat-select-trigger>
      {{getDisplayTextFromId && formControl.value?.[0]
      ? getDisplayTextFromId(formControl.value[0]) : (formControl.value?.[0] || '')}}
      <span *ngIf="(formControl.value?.length || 0) > 1" class="additional-selection">
        (+{{(formControl.value?.length || 0) - 1}} {{formControl.value?.length === 2 ? 'other' : 'others'}})
      </span>
    </mat-select-trigger>
    <mat-option *ngIf="(optionsList?.length || 0) > 10">
      <ngx-mat-select-search [formControl]="filterControl" placeholderLabel="Search..."
        noEntriesFoundLabel="No matching entries found"></ngx-mat-select-search>
    </mat-option>
    <ng-container *ngIf="getDisplayText">
      <mat-option *ngFor="let option of filteredOptions | async"
        [value]="getItemId(option)">{{getDisplayText(option)}}</mat-option>
    </ng-container>
    <ng-container *ngIf="!getDisplayText">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{option}}</mat-option>
    </ng-container>
  </mat-select>
</div>
