import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Constants {
  EncryptKey: string;
  SecretKeyForEncryptionWithIv: string;
  IV: string;
  APIBasePath!: string;
  finboxApiKey: string;
  //APIBasePathIdentity: string;
  APITypeApplication: string;
  APITypeIdentity: string;
  defaultLanguageId: number;
  notificationTimeout: number;
  commonAPIErrorMessage: string;
  validationMessages: any;
  APIRequestTimeout: number;
  unauthorizedAccessPage: string;
  defaultLanguage: string;
  appClientToken: string;
  timeFormat12H: string;
  timeFormat24H: string;
  defaultDateFormat: string;
  gidColumnHeaderHeight: number;
  momentFullDate: string;
  GridPageSize: number;
  defaultLanguageObj: any;
  countryCode: any;
  numbersOnly: any;
  mobileNoPattern: any;
  mobileNoGlobalPattern: any;
  alphanumberPattern: any;
  companyNamePattern: any;
  blockSpecialCharacter: any;
  ValidationMessageKeys: any;
  noOfListingRecords: any;
  yearEquivalentMilliSecond: any;
  educationLevels: any;
  documents: any;
  defaultSelectedPageCount: number;
  dateWithTimeFormat: string;
  numberRegex: any;
  emiCycleFormat: string;
  urlPattern: any;

  constructor() {
    this.EncryptKey = '##eco@ctive~IA~2022##';
    this.SecretKeyForEncryptionWithIv =
      environment.SECRET_KEY_FOR_ENCRYPTION_WITH_IV;

    this.IV = environment.ENCRYPTION_IV;
    this.APIBasePath = environment.APIBasePath;
    this.finboxApiKey = environment.FINBOX_API_KEY;
    this.countryCode = 'AU';
    this.APITypeApplication = 'APPLICATION';
    this.APITypeIdentity = 'IDENTITY';
    this.defaultLanguageId = 1;
    this.notificationTimeout = 7000;
    this.commonAPIErrorMessage = 'Server Error';
    this.APIRequestTimeout = 25000000;
    this.unauthorizedAccessPage = '/unauthorized-access';
    this.defaultLanguage = 'en';
    this.appClientToken = '49cc5a38631c460caa76db0c54c4aa5c';
    this.timeFormat12H = 'hh:mm a';
    this.timeFormat24H = 'HH:mm';
    this.emiCycleFormat = 'dd MMM';
    this.defaultDateFormat = 'dd-MMM-yyyy';
    this.dateWithTimeFormat = 'dd-MMM-yyyy h:mm a';
    this.momentFullDate = 'DD/MMM/YYYY HH:mm';
    this.gidColumnHeaderHeight = 60;
    this.GridPageSize = 10; // default grid page size..
    this.defaultLanguageObj = {
      id: 1,
      name: 'English',
      cultureCode: 'en',
      isRtl: false,
    };
    // tslint:disable-next-line: max-line-length
    this.numbersOnly =
      /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    this.blockSpecialCharacter = '^(?=.*[a-zA-Z])([a-zA-Z0-9_ ]+)$';
    this.mobileNoPattern = '^[0-9]{10}$';
    this.mobileNoGlobalPattern = '^[s()+-]*([0-9][s()+-]*){6,20}$';
    this.alphanumberPattern = '^[A-Za-z0-9 ]+$';
    this.companyNamePattern = '^[A-Za-z0-9 &()\\[\\]_-]+$';
    this.ValidationMessageKeys = {
      required: '',
      invalidEmail: '',
      minlength: '',
    };
    this.noOfListingRecords = [10, 20, 50, 100];
    this.defaultSelectedPageCount = 1;
    this.yearEquivalentMilliSecond = 31556952000;
    this.numberRegex = /[^0-9]*/g;
    this.urlPattern =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
  }
}

export enum InputType {
  Password = 'Password',
  Text = 'Text',
}

export enum ToastTypes {
  Success = 0,
  Warning = 1,
  Error = 2,
}

export enum ToastMessages {
  'Check your internet connection' = 0,
  'Server error, please try again later' = 1,
  'Please log in again' = 2,
  'Unauthorized access' = 3,
  'Server not reachable, please try again later' = 4,
}
