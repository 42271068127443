import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent implements ICellRendererAngularComp {
  private params: any;
  items: any[] = [];

  constructor() {}

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (this.params.items) {
      this.items = this.params.items;
    }
  }

  handleClick($event: any, item: any): void {
    item.clicked($event, this.params.data);
  }

  isItemHidden(item: any) {
    return item.isHidden ? item.isHidden(this.params.data) : false;
  }
}
