export const MAX_FILE_SIZE = 10 * 2 ** 20;

export const VEHICLE_OEMS: string[] = [
  'Altigreen',
  'AMO',
  'Ampere',
  'Astro Motors',
  'Ather',
  'Atul Auto',
  'Baaz Bikes',
  'Bajaj',
  'Batt.Re',
  'Bgauss',
  'Bounce',
  'BYD',
  'eSprinto',
  'ETO Motors',
  'Etrio',
  'Euler',
  'Exponent',
  'Gemopai',
  'Hero Electric',
  'Hero Motocorp',
  'Hyundai',
  'IPLT Electric Truck',
  'JBM Auto',
  'Jitendra EV',
  'Keto Motors',
  'Kinetic Green',
  'Lectrix',
  'Lohia Auto',
  'Mahindra',
  'MG',
  'MLR Auto',
  'Okinawa',
  'Ola',
  'Olectra',
  'Om Balajee',
  'Omega Seiki',
  'Piaggio',
  'PMI Electro Mobility',
  'Revolt',
  'Saera',
  'SES (Shema Electric)',
  'Speego',
  'Switch',
  'Tata',
  'Tunwal',
  'TVS',
  'U.P. Telelinks',
  'Vegh',
  'Others',
];

export const BATTERY_OEMS: string[] = [
  'Acme',
  'Agasty Energy Labs',
  'Amara Raja Batteries',
  'Amptek',
  'C4V',
  'Cygni',
  'Cygni Energy',
  'D Wellness',
  'esmito',
  'E Vega Mobility Labs',
  'EVLion',
  'EV Retron Energies',
  'Exicom',
  'Exide Industries',
  'Exponent',
  'Future Hi-tech Batteries',
  'GODI',
  'Green Fuel Energy',
  'Grinntech',
  'Hero MotoCorp',
  'Indigrid Technology',
  'Inverted energy ',
  'JLN',
  'Jlnphenix Energy',
  'Laurus Battery',
  'Li-S Energy',
  'liloop',
  'Lucas-TVS',
  'Minda',
  'Mobilus Energies',
  'Natural Battery Technologies',
  'Pastiche Energy Solutions',
  'Pixcell',
  'Pure',
  'RACEnergy',
  'Tata Group – Tata Power/Tata Chemicals',
  'TDSG',
  'Trontek',
  'Yati Infotech',
];

export const VEHICLE_TYPES: string[] = [
  'e2W Passenger',
  'e2W Commercial',
  'e3W Passenger',
  'e3W Commercial',
  'e4W Passenger',
  'e4W Commercial',
  'Others',
];

export const FUNDING_PREFERENCES: string[] = [
  'e2W Passenger',
  'e2W Commercial',
  'e3W Passenger',
  'e3W Commercial',
  'e4W Passenger',
  'e4W Commercial',
  'Battery OEM',
  'Battery Tech',
  'Charging Infra',
  'General EV Tech',
  'Others',
];

export const VEHICLE_SPEEDS: string[] = ['Low Speed', 'High Speed'];

export const SECURITIES: string[] = [
  'Bank Guarantee',
  'Promoter Guarantee',
  'Co - Applicant',
  'Co - Borrower',
  'PDCs',
  'Promissory Notes',
  'Corporate Guarantee',
  'Collateral',
  'Charge on Asset',
  'Others',
];

export const SHARE_HOLDING_TYPES: string[] = [
  'Individuals (Indian) (Promoters)',
  'Individuals (Indian) (Non-promoters)',
  'Individuals (NRI) (Promoters)',
  'Individuals (NRI) (Non-promoters)',
  'Individuals (Foreign Nationals) (Promoters)',
  'Individuals (Foreign Nationals) (Non-promoters)',
  'Central Government',
  'State Government',
  'Government Companies',
  'Insurance Companies',
  'Banks',
  'Financial Institutions',
  'Foreign Institutional Investors',
  'Mutual Funds',
  'Venture Capital',
  'Body Corporate',
  'Others',
];

export const B2B_CONTRACT_TYPES: string[] = [
  'Revenue Sharing',
  'Monthly Committed Revenue',
  'Annual Committed Revenue',
  'No Committed Revenue',
];

export const B2B_RENEWAL_MODES: string[] = ['Opt In', 'Opt Out'];

export const OPERATING_LOCATIONS: string[] = [
  'Agra',
  'Ahmedabad',
  'Bangalore',
  'Bhopal',
  'Chandigarh',
  'Chennai',
  'Delhi',
  'Faridabad',
  'Ghaziabad',
  'Gurugram',
  'Hyderabad',
  'Indore',
  'Jaipur',
  'Kanpur',
  'Kolkata',
  'Lucknow',
  'Ludhiana',
  'Meerut',
  'Mumbai',
  'Nagpur',
  'Nashik',
  'Noida',
  'Patna',
  'Pimpri-Chinchwad',
  'Pune',
  'Rajkot',
  'Rohtak',
  'Surat',
  'Thane',
  'Vadodara',
  'Varanasi',
  'Visakhapatnam',
  'Warangal',
];

export const BUSINESS_TYPES: string[] = [
  'Fleet Operator',
  'Battery OEM',
  'Battery Tech',
  'Channel Partner',
  'Charging Infra',
  'General EV Tech',
  'Marketplace',
  'Vehicle OEM',
  'Others',
];

export const CUSTOMER_TYPES: string[] = [
  'B2B',
  'B2C',
  'D2C',
  'B2B2C',
  'Others',
];

export const AIS_CERTIFICATION_TYPES: string[] = [
  'AIS 156 Phase 2 Certified',
  'AIS 156 Phase 1 Certified',
  'NA',
];

export const BATTERY_VOLTAGE_OPTIONS: string[] = ['48', '60', '72'];

export const BATTERY_CAPACITY_OPTIONS: string[] = [
  '20 - 30',
  '30 - 40',
  '40 - 50',
  '50 - 60',
];

export const AnalyticsTags = {
  VIEW_DETAILS_CLICKED: 'VIEW_DETAILS_CLICKED',
  LIVE_DEALS: 'LIVE_DEALS',
  LIVE_DEALS_VIEW_DETAILS: 'LIVE_DEALS_VIEW_DETAILS',
  STORE_MANAGEMENT: 'STORE_MANAGEMENT',
  ADD_NEW_MODEL_BUTTON_CLICKED: 'ADD_NEW_MODEL_CLICKED',
  ADD_NEW_MODEL: 'ADD_NEW_MODEL',
  EDIT_ON_ROAD_PRICE_CLICKED: 'EDIT_ON_ROAD_PRICE_CLICKED',
  EDIT_ON_ROAD_PRICE: 'EDIT_ON_ROAD_PRICE',
  LOAN_OFFER: 'LOAN_OFFER',
  VIEW_OFFER_DETAILS_CLICKED: 'VIEW_OFFER_DETAILS_CLICKED',
  VIEW_OFFER_DETAILS: 'VIEW_OFFER_DETAILS',
  STANDARD_OFFERS_LIST: 'STANDARD_OFFERS_LIST',
  STANDARD_OFFER_CLICKED: 'STANDARD_OFFER_CLICKED',
  STANDARD_OFFER: 'STANDARD_OFFER',
  VEHICLE_MODEL_SEARCH: 'VEHICLE_MODEL_SEARCH',
  NO_ITEMS_ON_VEHICLE_MODEL_SEARCH: 'NO_ITEMS_ON_VEHICLE_MODEL_SEARCH',
  ELIGIBLE_OFFERS_FORM: 'ELIGIBLE_OFFERS_FORM',
  TENURE_CHANGED: 'TENURE_CHANGED',
  TENURE: 'TENURE',
  DOWN_PAYMENT: 'DOWN_PAYMENT',
  DOWN_PAYMENT_CHANGED: 'DOWN_PAYMENT_CHANGED',
  VEHICLE_MODEL: 'VEHICLE_MODEL',
  VEHICLE_MODEL_CHANGED: 'VEHICLE_MODEL_CHANGED',
  LOAN_APPLIED_MESSAGE_PAGE: 'LOAN_APPLIED_MESSAGE_PAGE',
  OK_BUTTON_CLICKED: 'OK_BUTTON_CLICKED',
  OK_BUTTON: 'OK_BUTTON',
  B2C_FUND_REQUEST_CREATED: 'B2C_FUND_REQUEST_CREATED',
  B2C_FUND_REQUEST_OFFER_APPLIED: 'B2C_FUND_REQUEST_OFFER_APPLIED',
  CHANGE_MODEL_BUTTON_CLICKED: 'CHANGE_MODEL_BUTTON_CLICKED',
  CHANGE_MODEL_SELECTION_APPLIED: 'CHANGE_MODEL_SELECTION_APPLIED',
};

export const CUSTOMER_LOAN_TENURES = [6, 8, 12, 14, 18, 24, 30, 36, 42, 48];

export const MARITAL_STATUSES = ['Single', 'Married'];

export const PROPERTY_STATUSES = [
  'Owned',
  'Corporate Provided',
  'Relatives House',
  'Rented',
];

export const GENDERS = ['Male', 'Female', 'Other'];

export const TYPES_OF_ORGANIZATION = [
  'Partnership Firm',
  'Private Limited Company',
  'Public Limited Company',
  'Proprietorship Firm',
];

export const REMARKS = {
  SystemGeneratedOffer: 'System Generated Offer',
};

export const ASSET_MODEL_SUBTYPES = ['LowSpeed', 'HighSpeed'];

export const FIELD_SALES_EXECUTIVE_NAMES = [
  'Direct Lead',
  'Avinash',
  'Karthik',
  'Vinoda',
  'Dion Alex',
  'Gurumurthy',
  'Shivaputra',
];
