import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(private http: HttpClient) {}

  getSignedUrl(id: string): Observable<any> {
    const url = `document/${id}/signed-url`;
    return this.http.get(url);
  }

  upload(data: any, queryParams?: any): Observable<any> {
    let url = 'document/upload';
    if (queryParams && Object.keys(queryParams).length) {
      const queryParamKeys = Object.keys(queryParams);
      for (let i = 0; i < queryParamKeys.length; i++) {
        const queryParamKey = queryParamKeys[i];
        let prefix = i === 0 ? '?' : '&';
        url += `${prefix}${queryParamKey}=${queryParams[queryParamKey]}`;
      }
    }
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return this.http.post(url, formData, {
      params: {
        _config: '{ "bypassContentType": "true" }',
      },
    });
  }

  delete(id: string, docTypeId?: string): Observable<any> {
    const url = 'document/' + id;
    return this.http.delete(url, {
      body: { docTypeId },
    });
  }
}
