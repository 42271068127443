<div class="app-login-wrap">
  <div class="app-login-left-block">
    <img src="assets/images/login_banner.jpg" alt="Ohm-Mobility" class="logo">
  </div>
  <div class="app-login-right-block">
    <div class="body-container">
      <img src="assets/images/logos/ohm-logo-wide-green.png" width="285" alt="Ohm-Mobility" class="logo">

      <form [formGroup]="loginDetailsForm" (keyup.enter)="submitBtn._elementRef.nativeElement.click()">
        <mat-form-field class="form-group">
          <mat-label>Mobile Number</mat-label>
          <input matInput type="text" formControlName="mobileNumber"
            onKeyPress="if(this.value.length==10) return false;" class="form-control">
          <ohm-control-messages [control]="loginDetailsForm.get('mobileNumber')"
            [messageKeys]="{required: 'MESSAGE.REQUIRED_THIS_FIELD', pattern: 'MESSAGE.PATTERN_MOBILE_NUMBER'}">
          </ohm-control-messages>
        </mat-form-field>
        <mat-form-field [hidden]="!showOtp" class="form-group">
          <mat-label>OTP</mat-label>
          <input #otp matInput type="text" formControlName="otp" onKeyPress="if(this.value.length==4) return false;"
            class="form-control">
          <ohm-control-messages [control]="loginDetailsForm.get('otp')" [messageKeys]="{required: 'Required'}">
          </ohm-control-messages>
        </mat-form-field>
        <button mat-raised-button color="primary" class="action-button" (click)="login()"
          #submitBtn>{{submitButtonText}}</button>
      </form>
    </div>
    <div class="footer">
      <div class="contact">
        <a href="mailto:contact@ohmmobility.com">contact@ohmmobility.com</a>
        <span class="footer-mobile-number">+91-8867037812</span>
      </div>
      <div class="copyright">
        ©2020 by OHM Mobility
      </div>
    </div>
  </div>
</div>
