<button (click)="btnClickedHandler()" class="app-c-btn-payment app-c-btn--tertiary-toggle" *ngIf="isOn">
  <mat-icon>
    toggle_on
  </mat-icon>
</button>
<button (click)="btnClickedHandler()" class="app-c-btn-payment app-c-btn--tertiary-toggle" *ngIf="!isOn">
  <mat-icon class="material-icons-outlined">
    toggle_off
  </mat-icon>
</button>
