<div *ngIf="active" [ngClass]="{'preloader-active preloader-visible': active }" dir="ltr">
    <div class="preloader-container">
        <div class="preloader-overlay"></div>
        <div class="preloader-message-container" id="preloaderContainer" aria-live="assertive" aria-atomic="true">
            <div class="preloader-message">
                <div class="preloader--wrap">
                    <div class="showbox pb-0">
                        <div class="trl-l-loader__preloader">
                            <div class="trl-l-loader-circle trl-l-loader-circle__one"></div>
                            <div class="trl-l-loader-circle trl-l-loader-circle__two"></div>
                            <div class="trl-l-loader-circle trl-l-loader-circle__three"></div>
                            <div class="trl-l-loader-circle trl-l-loader-circle__four"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>