import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/services/auth.service';
import { CryptoService } from '../../../core/services/crypto.service';
import { IotReportApiService } from '../iot-report-api-service';

@Component({
  selector: 'app-iot-dashboard',
  templateUrl: './iot-dashboard.component.html',
  styleUrls: ['./iot-dashboard.component.scss'],
})
export class IotDashboardComponent implements OnInit {
  safeIframeUrl: SafeResourceUrl = '';

  constructor(
    private cryptoService: CryptoService,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private apiService: IotReportApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const entity = this.authService.getEntity();
    const requestBody: any = {
      resource: {},
      params: {},
    };
    if (this.authService.isBorrower()) {
      requestBody.params['borrower_id'] = entity.id;
      requestBody.resource['dashboard'] = environment.borrowerDashboardId;
    } else {
      requestBody.params['lender_id'] = entity.id;
      requestBody.resource['dashboard'] = environment.lenderDashboardId;
    }
    this.apiService
      .getDashboardToken(requestBody)
      .subscribe((response: any) => {
        try {
          const token = this.cryptoService.decrypt(response.encryptedToken);
          const parameters = `bordered=false&hide_parameters=${
            this.authService.isBorrower()
              ? 'borrower_name,borrower_id,lender_id'
              : 'lender_name,lender_id,borrower_id'
          }`;
          const iframeUrl = `${environment.metabaseBaseUrl}/embed/dashboard/${token}#${parameters}`;
          this.safeIframeUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
        } catch (error) {
          this.router.navigate(['dealer/loan-requests/list'], {
            replaceUrl: true,
          });
        }
      });
  }
}
