import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { IotDashboardComponent } from './iot-dashboard/iot-dashboard.component';
import { IotReportApiService } from './iot-report-api-service';

@NgModule({
  declarations: [IotDashboardComponent],
  imports: [SharedModule],
  providers: [IotReportApiService],
})
export class IotReportModule {}
