import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth-guard.service';
import { IotDashboardComponent } from './features/iot-report/iot-dashboard/iot-dashboard.component';
import { LoginComponent } from './features/user/login/login.component';

const routes: Routes = [
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'borrower',
    redirectTo: 'borrower/profile',
    pathMatch: 'full',
  },
  {
    path: 'borrower/profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/borrower-profile/borrower-profile.module').then(
        (m) => m.BorrowerProfileModule
      ),
  },
  {
    path: 'borrower/:borrowerId/:fundRequestId/profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/borrower-profile/borrower-profile.module').then(
        (m) => m.BorrowerProfileModule
      ),
  },
  {
    path: 'lender/profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/lender-profile/lender-profile.module').then(
        (m) => m.LenderProfileModule
      ),
  },
  {
    path: 'lender/deals',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/lender-deals/lender-deals.module').then(
        (m) => m.LenderDealsModule
      ),
  },
  {
    path: 'lender/b2c',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/lender-b2c/lender-b2c.module').then(
        (m) => m.LenderB2cModule
      ),
  },
  {
    path: 'borrower/deals',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/borrower-deals/borrower-deals.module').then(
        (m) => m.BorrowerDealsModule
      ),
  },
  {
    path: 'borrower/:borrowerId/deals',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/borrower-deals/borrower-deals.module').then(
        (m) => m.BorrowerDealsModule
      ),
  },
  {
    path: 'ohm-pulse',
    canActivate: [AuthGuard],
    component: IotDashboardComponent,
  },
  {
    path: 'dealer',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/dealer/dealer.module').then((m) => m.DealerModule),
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
  },
  { path: 'login', canActivate: [AuthGuard], component: LoginComponent },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'master-dealer',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/master-dealer/master-dealer.module').then(
        (m) => m.MasterDealerModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
