import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  constructor(private http: HttpClient) {}

  getAllFeatures() {
    const url = 'features';
    return this.http.get(url, {
      params: {
        _config: '{"noNotifyError": "true"}',
      },
    });
  }
}
