import * as moment from 'moment';
moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

export class DateUtilsService {
  constructor() {}

  static getDateComparatorParam(): any {
    return {
      comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
        if (cellValue == null) {
          return 0;
        }
        return moment(cellValue)
          .startOf('day')
          .diff(filterLocalDateAtMidnight, 'day');
      },
    };
  }

  static getDateComparator(): any {
    return (
      valueA: any,
      valueB: any,
      nodeA: any,
      nodeB: any,
      isInverted: boolean
    ) => {
      if (valueA == null || valueB == null) {
        return 0;
      }
      return moment(valueB)
        .startOf('day')
        .diff(moment(valueA).startOf('day'), 'day');
    };
  }

  static getFormattedDate(date: string): string {
    if (!date) {
      return '';
    }
    return moment(date).format('DD MMM YYYY');
  }

  static getFormattedDateForApi(date: moment.MomentInput): string {
    return moment(date).format('DD/MM/YYYY');
  }

  static getMoment(): moment.Moment {
    return moment();
  }

  static getDateInFormat(date: string, format: string): string {
    if (!date) {
      return '';
    }
    return moment(date).format(format);
  }

  static getTimeSpan(date: string): string {
    var isToday = moment().isSame(moment(date), 'd');
    let format;
    if (isToday) {
      format = 'h:mm a';
    } else if (moment().isSame(moment(date), 'y')) {
      format = 'DD MMM h:mm a';
    } else {
      format = 'DD MMM YY h:mm a';
    }
    return moment(date).format(format);
  }

  static getFormattedDateTime(date: string): string {
    return moment(date).format('DD MMM YYYY, hh:mm a');
  }

  static getDateFromString(date: string, fromFormat: string) {
    return moment(date, fromFormat).toDate();
  }

  static getStartOfCurrentFinancialYear() {
    const today = moment();
    const startOfCurrentFinancialYear = moment(`${today.year()}-04-01`).startOf(
      'd'
    );
    if (today.isBefore(startOfCurrentFinancialYear, 'd')) {
      return startOfCurrentFinancialYear.subtract(1, 'y');
    }
    return startOfCurrentFinancialYear;
  }

  static isAfter(
    date1: moment.MomentInput,
    date2: moment.MomentInput
  ): boolean {
    return moment(date1).isAfter(date2);
  }
}

export function getDateInMoment(
  date: moment.MomentInput,
  fromFormat?: moment.MomentFormatSpecification
): moment.Moment {
  return moment(date, fromFormat);
}
