import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  updateUserType(userId: string, type: string): Observable<any> {
    const url = `users/${userId}/user-type`;
    return this.http.put(url, { type });
  }

  sendEmailOtp(data: any): Observable<any> {
    const url = 'users/send-email-otp';
    return this.http.post(url, data, {
      params: {
        _config: '{"noNotifyError": "true"}',
      },
    });
  }

  validateEmailOtp(data: any): Observable<any> {
    const url = 'users/validate-email-otp';
    return this.http.post(url, data);
  }

  signUpUser(data: any): Observable<any> {
    const url = 'users/sign-up';
    return this.http.post(url, data);
  }

  signUpDealerUser(data: any): Observable<any> {
    const url = 'users/sign-up-dealer';
    return this.http.post(url, data);
  }

  searchCompanyNames(type: string, keyword: string): Observable<any> {
    const url = `external/company-details/search?type=${type}&keyword=${keyword}`;
    return this.http.get(url, {
      params: {
        _config: '{"noLoader": "true", "noNotifyError": "true"}',
      },
    });
  }

  getUserRolePermissions(userId: string): Observable<any> {
    const url = `users/${userId}/permissions`;
    return this.http.get(url);
  }
}
