import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';

@Injectable()
export class AlertUtilsService {
  showDeleteConfirmation(onConfirm: () => void) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this!",
      icon: 'warning',
      showCancelButton: true,
      focusCancel: true,
      cancelButtonColor: '#6e7881c4',
      confirmButtonColor: '#f14668',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }
    });
  }

  showSimpleConfirmation(confirmationOptions: ConfirmationOptions) {
    this.showConfirmation('', 'Are you sure?', 'Confirm', confirmationOptions);
  }

  showConfirmation(
    title: string,
    message: string,
    confirmButtonText: string,
    confirmationOptions: ConfirmationOptions
  ) {
    const options: SweetAlertOptions = {
      title: title,
      html: message,
      cancelButtonColor: '#6e7881c4',
      confirmButtonColor: confirmationOptions.confirmButtonColor || '#128e67',
      confirmButtonText: confirmButtonText,
      heightAuto: false,
    };
    if (confirmationOptions.icon) {
      options.icon = confirmationOptions.icon;
    }
    if (confirmationOptions.didDestroy) {
      options.didDestroy = confirmationOptions.didDestroy;
    }
    if (Object.hasOwn(confirmationOptions, 'focusCancel')) {
      options.focusCancel = confirmationOptions.focusCancel;
    }
    if (Object.hasOwn(confirmationOptions, 'cancelButtonText')) {
      options.cancelButtonText = confirmationOptions.cancelButtonText;
    }
    if (Object.hasOwn(confirmationOptions, 'showCancelButton')) {
      options.showCancelButton = confirmationOptions.showCancelButton;
    } else {
      options.showCancelButton = true;
    }
    Swal.fire(options).then((result) => {
      if (result.isConfirmed && confirmationOptions.onConfirm) {
        confirmationOptions.onConfirm();
      } else if (
        result.isDismissed &&
        result.dismiss === Swal.DismissReason.cancel &&
        confirmationOptions.onDismiss
      ) {
        confirmationOptions.onDismiss();
      }
    });
  }

  showSaveChangesConfirmation(
    onClose: (isDismissed: boolean, saveChanges: boolean) => void
  ) {
    Swal.fire({
      title: 'Are you sure?',
      html: 'You seem like changed something.<br>Do you want to save those before you leave?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonColor: '#006241',
      confirmButtonText: 'Save',
      denyButtonText: 'Skip Changes!',
    }).then((result) => {
      onClose(result.isDismissed, result.isConfirmed);
    });
  }
}

interface ConfirmationOptions {
  icon?: SweetAlertIcon | null;
  showCancelButton?: boolean;
  onConfirm?: (() => void) | null;
  onDismiss?: (() => void) | null;
  didDestroy?: (() => void) | null;
  confirmButtonColor?: string;
  cancelButtonText?: string;
  focusCancel?: boolean;
}
