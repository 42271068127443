import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NgControl,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

/** Custom `MatFormFieldControl` for multiple select drop down input. */
@Component({
  selector: 'select-input',
  templateUrl: 'select-input.component.html',
  styleUrls: ['select-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SelectInput }],
  host: {
    '[id]': 'id',
  },
})
export class SelectInput
  implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy
{
  static nextId = 0;

  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'select-input';
  id = `select-input-${SelectInput.nextId++}`;
  formControl = new FormControl();
  filterControl: FormControl<string | null> = new FormControl<string>('');
  filteredOptions: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  protected _onDestroy = new Subject<void>();
  valueOnDisabledState = '';

  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    return !this.selectedOptions || this.selectedOptions.length === 0;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input() getDisplayText!: (item: any) => string;
  @Input() getItemId!: (item: any) => string;
  @Input() getDisplayTextFromId!: (id: any) => string;

  @Input('aria-describedby') userAriaDescribedBy!: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder!: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formControl.disable() : this.formControl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): string | null {
    if (this.selectedOptions.length) {
      return this.selectedOptions.join(',');
    }
    return null;
  }
  set value(value: string | null) {
    if (value) {
      this._selectedOptions = value.split(',');
    } else {
      this._selectedOptions = [];
    }
    this.formControl.setValue(this._selectedOptions);
    this.valueOnDisabledState = this.value
      ? this.value.replaceAll(',', ', ')
      : '';
    this.stateChanges.next();
  }

  @Input()
  get optionsList(): any[] {
    return this._optionsList;
  }
  set optionsList(value: any[]) {
    this._optionsList = value;
    this.filteredOptions.next(this._optionsList.slice());
    this.stateChanges.next();
  }
  private _optionsList!: any[];

  get selectedOptions(): string[] {
    return this._selectedOptions;
  }
  set selectedOptions(value: string[]) {
    this._selectedOptions = value;
    this.stateChanges.next();
    this.writeValue(this.value);
  }
  private _selectedOptions!: string[];

  get errorState(): boolean {
    return this.formControl.invalid && this.touched;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.filterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterOptions();
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement =
      this._elementRef.nativeElement.querySelector('.mat-select-input')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {}

  writeValue(value: string | null): void {
    this.value = value;
    this.onChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChange(this.value);
  }

  protected filterOptions() {
    if (!this.optionsList) {
      return;
    }
    // get the search keyword
    let search = this.filterControl.value;
    if (!search) {
      this.filteredOptions.next(this.optionsList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    if (typeof this._optionsList[0] === 'string') {
      this.filteredOptions.next(
        this.optionsList.filter(
          (option) => option.toLowerCase().indexOf(search!) > -1
        )
      );
    } else {
      this.filteredOptions.next(
        this.optionsList.filter(
          (option) =>
            this.getDisplayText(option).toLowerCase().indexOf(search!) > -1
        )
      );
    }
  }
}
