import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { BorrowerProfileStatus } from '../enums/borrower-profile-status.enum';
import { CustomerPreference } from '../enums/customer-preference.enum';
import { EntityType } from '../enums/entity.enum';
import { LenderProfileStatus } from '../enums/lender-profile-status.enum';
import { AuthService } from './../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    snapshot: RouterStateSnapshot
  ): any {
    // Auth guard logic here.
    const urls = snapshot.url.split('/');
    const tokenStatus = this.authService.isLoggedIn();
    if (tokenStatus) {
      const entity = this.authService.getEntity();
      if (entity?.id && entity?.isActive) {
        if (
          snapshot.url === '/user/update-user-type' ||
          snapshot.url === '/user/verify-email' ||
          snapshot.url === '/user/signup' ||
          snapshot.url === '/?source=pwa' ||
          urls[1] === '' ||
          urls[1] === 'login' ||
          urls[1] === 'home'
        ) {
          this.navigateToPostLoginPage();
        } else {
          if (urls[1] === 'lender' && !this.authService.isLender()) {
            this.navigateToPostLoginPage();
          } else if (urls[1] === 'dealer' && !this.authService.isDealer()) {
            this.navigateToPostLoginPage();
          } else if (urls[1] === 'borrower' && this.authService.isDealer()) {
            this.navigateToPostLoginPage();
          }
          if (
            urls[1] === 'dealer' &&
            urls[2] !== 'store-management' &&
            this.authService.getEntityStatus() != BorrowerProfileStatus.Approved
          ) {
            this.router.navigate(['dealer/store-management/models'], {
              replaceUrl: true,
            });
          }
          return true;
        }
      } else {
        if (this.authService.isOhmAdmin()) {
          if (
            snapshot.url === '/admin/switch-entity' ||
            snapshot.url === '/admin/lender-bre' ||
            snapshot.url === '/admin/manage-assets' ||
            snapshot.url === '/admin/dealer-sales-person-mapping' ||
            snapshot.url.startsWith('/admin/reports/beat')
          ) {
            return true;
          }
          this.router.navigate(['admin/switch-entity'], { replaceUrl: true });
          return false;
        }
        if (this.authService.isMasterDealer()) {
          if (snapshot.url === '/master-dealer/switch-entity') {
            return true;
          }
          this.router.navigate(['master-dealer/switch-entity'], {
            replaceUrl: true,
          });
          return false;
        }
        if (!this.authService.getUserData().entityType) {
          if (snapshot.url === '/user/update-user-type') {
            return true;
          }
          this.router.navigate(['user/update-user-type'], { replaceUrl: true });
        } else if (
          this.authService.getUserData().entityType === EntityType.Dealer
        ) {
          if (snapshot.url === '/user/dealer-signup') {
            return true;
          }
          this.router.navigate(['user/dealer-signup'], { replaceUrl: true });
        } else if (this.authService.getUserData().emailVerified) {
          if (snapshot.url !== '/user/signup') {
            this.router.navigate(['user/signup'], { replaceUrl: true });
          } else {
            return true;
          }
        } else {
          if (snapshot.url !== '/user/verify-email') {
            this.router.navigate(['user/verify-email'], { replaceUrl: true });
          } else {
            return true;
          }
        }
      }
    } else {
      if (urls[1] == 'login' || urls[1].startsWith('login?')) {
        return true;
      }
      const queryParams: any = {};
      if (snapshot.url && snapshot.url != '/') {
        queryParams.redirectUrl = snapshot.url.substring(1);
      }
      this.router.navigate(['login'], {
        replaceUrl: true,
        queryParams,
      });
    }
  }

  navigateToPostLoginPage() {
    if (this.authService.isLender()) {
      const customerPreferences = this.authService
        .getEntity()
        .customerPreferences.split(',');

      if (
        this.authService.getEntity().status === LenderProfileStatus.Approved &&
        customerPreferences.includes(CustomerPreference.B2bBusiness)
      ) {
        this.router.navigate(['lender/deals/live'], { replaceUrl: true });
      } else if (customerPreferences.includes(CustomerPreference.B2b2c)) {
        this.router.navigate(['lender/b2c/leads'], { replaceUrl: true });
      } else {
        this.router.navigate(['lender/profile/info'], { replaceUrl: true });
      }
    } else if (this.authService.isDealer()) {
      if (
        this.authService.getEntityStatus() == BorrowerProfileStatus.Approved
      ) {
        this.router.navigate(['dealer/available-models'], {
          replaceUrl: true,
        });
      } else {
        this.router.navigate(['dealer/store-management/models'], {
          replaceUrl: true,
        });
      }
    } else {
      this.router.navigate(['borrower/deals'], {
        replaceUrl: true,
      });
    }
  }
}
