import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { EntityType } from '../enums/entity.enum';
import { AnalyticsService } from './analytics.service';
import { LocalStorage } from './localstore.service';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public onLoginCompleted: Subject<object> = new Subject();
  public accessToken: any;
  public tokenKey = 'ohmBuzzToken';
  public entityStatusKey = 'ohmBuzzEntityStatus';
  public userRolePermissionsKey = 'ohmBuzzUserRolePermissions';

  constructor(
    private localStore: LocalStorage,
    private analyticsService: AnalyticsService
  ) {}

  /**
   * Method to check if the user is loggedIn
   */
  isLoggedIn(): any {
    if (this.accessToken) {
      return true;
    }
    const existingToken = this.localStore.get(this.tokenKey);
    if (existingToken) {
      this.accessToken = existingToken.accessToken;
      return true;
    }
    return false;
  }

  getRefreshToken() {
    const existingToken = this.localStore.get(this.tokenKey);
    if (existingToken) {
      return existingToken.refreshToken;
    }
    return null;
  }

  getEntity() {
    const decodedToken = helper.decodeToken(this.accessToken);
    return decodedToken?.entity;
  }

  getUserData() {
    const decodedToken = helper.decodeToken(this.accessToken);
    return decodedToken?.user;
  }

  isBorrower() {
    return this.getUserData()?.entityType === EntityType.Borrower;
  }

  isLender() {
    return this.getUserData().entityType === EntityType.Lender;
  }

  isDealer() {
    const decodedToken = helper.decodeToken(this.accessToken);
    return decodedToken?.roles?.includes(EntityType.Dealer);
  }

  isOhmAdmin() {
    const decodedToken = helper.decodeToken(this.accessToken);
    return this.checkIsOhmAdmin(decodedToken);
  }

  isMasterDealer() {
    const decodedToken = helper.decodeToken(this.accessToken);
    return this.checkIsMasterDealer(decodedToken);
  }

  private checkIsOhmAdmin(decodedToken: any) {
    return decodedToken?.roles?.includes(EntityType.Ohm);
  }

  private checkIsMasterDealer(decodedToken: any) {
    return decodedToken?.roles?.includes(EntityType.MasterDealer);
  }

  login(token: any) {
    if (token) {
      this.accessToken = token.accessToken;
      const decodedToken = helper.decodeToken(this.accessToken);
      const isOhmAdmin = this.checkIsOhmAdmin(decodedToken);
      const isMasterDealer = this.checkIsMasterDealer(decodedToken);
      this.analyticsService.setUser(
        decodedToken?.user?.id,
        {
          $name: decodedToken?.user?.name,
          isOhmAdmin,
          isMasterDealer,
        },
        {
          entityType: decodedToken?.user?.entityType,
          entityName: decodedToken?.entity?.name,
        }
      );
      if (
        (decodedToken?.entity?.id && decodedToken?.entity?.isActive) ||
        isOhmAdmin ||
        isMasterDealer
      ) {
        this.localStore.store(this.tokenKey, token);
        this.localStore.store(
          this.entityStatusKey,
          decodedToken?.entity?.status
        );
      }
      return true;
    } else {
      return false;
    }
  }

  getEntityStatus(): string {
    return this.localStore.get(this.entityStatusKey);
  }

  setEntityStatus(status: string) {
    this.localStore.store(this.entityStatusKey, status);
  }

  getUserRolePermissions() {
    const userRolePermissions = this.localStore.get(
      this.userRolePermissionsKey
    );
    if (
      userRolePermissions &&
      userRolePermissions.userId === this.getUserData().id
    ) {
      return userRolePermissions;
    }
    return null;
  }

  getUserPermissions() {
    return this.getUserRolePermissions()?.permissions;
  }

  getUserRole() {
    return this.getUserRolePermissions()?.role;
  }

  setUserRolePermissions(userRolePermissions: any) {
    this.localStore.store(this.userRolePermissionsKey, userRolePermissions);
  }

  /**
   * Method to logout of the application (API)
   */
  logout() {
    this.clearOfflineData();
    this.analyticsService.resetUser();
  }

  /**
   * Method to clear all offline data
   */
  clearOfflineData() {
    this.accessToken = null;
    this.localStore.clearAll();
  }
}
